// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api: {
        url: 'api.megapteka.dev',
        port: '',
        protocol: 'https',
        queryParams: [{ key: 'ngsw-bypass', value: 'true' }],
        header: {
            'Content-Type': 'application/json',
            platform: 8,
        },
    },
    default: {
        startPage: 1,
        itemsPerPage: 20,
        minPerPage: 1,
        maxPerPage: 500,
        perPageList: [10, 20, 50, 100],
    },
    startUrl: '/drugs',
    titlePostfix: ' — Личный кабинет производителя. Megapteka.ru',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
